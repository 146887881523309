import '@natscale/react-calendar/dist/main.css';
import "react-datepicker/dist/react-datepicker.css";

import React from 'react';
import {find, reduce, propEq, range, sort, map, take, prop} from 'ramda';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import { Calendar } from '@natscale/react-calendar';
import DatePicker from 'react-datepicker';

import apiAppointment from '../api/appointment-api';
import apiUpload from '../api/upload-api';
import apiHoliday from '../api/canada-holidays-api';
import {formatDate, firstDateOfMonth, getDate, formatTime, isToday} from '../helper/datetime-helper';
import {PORT_COQUITLAM, NORTH_VANCOUVER} from '../helper/constant';
import {useLoadingOveray} from './LoadingOverlayContext';
import FormikField from './FormikField';
import ButtonPrimary from './ButtonPrimary';
import ButtonDanger from './ButtonDanger';
import Input from './Input';
import Textarea from './Textarea';
import TimeSelect from './TimeSelect';
import Grid from './Grid';
import Icon from './Icon';
import Dropdown from './Dropdown';
import RadioButton from './RadioButton';
import UploadFile from './UploadFile';
import Checkbox from './Checkbox';

const ERROR_ICBC_FILE_MISSING = "Please upload an ICBC insurance, the first and second pages.";

const thisYear = (new Date()).getFullYear();
const carYears = [{value: "", text: "Select vehicle year"}]
  .concat(map(d => ({value: d, text: d}), sort((a, b) => b - a, range(thisYear - 40, thisYear + 1))));
// const carDoors = [
//   {value: "", text: "Select number of doors"},
//   {value: "4", text: "4 doors"},
//   {value: "2", text: "2 doors"},
// ];
const carDamages = [
  {value: "", text: "Select damage area"},
  {value: "Windshield", text: "Windshield"},
  {value: "Door glass", text: "Door glass"},
  {value: "Back glass", text: "Back glass"},
  {value: "Quarter glass", text: "Quarter glass"},
];
const damageCuases = [
  {value: "", text: "Select cause of the damage"},
  {value: "Flying Object", text: "Flying Object"},
  {value: "Shifting Cargo", text: "Shifting Cargo"},
  {value: "Theft From Auto", text: "Theft From Auto"},
  {value: "Vandalism", text: "Vandalism"},
  {value: "Other", text: "Other"},
];
const insuranceCompanys = [
  {value: "ICBC", text: "ICBC"},
  {value: "Private insurance", text: "Private insurance"}
];

const initialValues = {
  id: "",
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  preferCommunication: "Phone",
  carMake: "",
  carModel: "",
  carYear: "",
  //carMileage: 0,
  //carDoors: "",
  carDamage: "",
  carDamageSection: [],
  causeOfDamage: "",
  lossDate: "",
  location: PORT_COQUITLAM,
  bookingDate: "",
  bookingTime: "",
  needInsuranceClaim: "",
  insuranceCompany: "ICBC",
  //mobileService: "No",
  courtesyCar: "No",
  comment: "",
  filePrefix: "",
  files: []
};

const validationSchema = Yup.object({
  id: Yup.string(),
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  phone: Yup.string().required('Required'),
  email: Yup.string().required('Required'),
  preferCommunication: Yup.string(),
  carMake: Yup.string().required('Required'),
  carModel: Yup.string().required('Required'),
  carYear: Yup.number().required('Required'),
  //carMileage: Yup.number().required('Required'),
  //carDoors: Yup.string().required('Required'),
  carDamage: Yup.string().required('Required'),
  carDamageSection: Yup.array().required('Required'),
  causeOfDamage: Yup.string().required('Required'),
  lossDate: Yup.date().required('Required').typeError('Please select a date of loss'),
  location: Yup.string().required('Required'),
  bookingDate: Yup.date().required('Required').typeError('Please select an available date'),
  bookingTime: Yup.string().required('Required'),
  needInsuranceClaim: Yup.string().required('Required'),
  insuranceCompany: Yup.string().required('Required'),
  //mobileService: Yup.string().required('Required'),
  courtesyCar: Yup.string(),
  comment: Yup.string(),
  filePrefix: Yup.string(),
  files: Yup.array()
});

const userInfo = [
  {id: "firstName", name: "firstName", label: "First Name *", placeholder:"Jane"},
  {id: "lastName", name: "lastName", label: "Last Name *", placeholder:"Deer" },
  {id: "phone", name: "phone", label: "Phone *", placeholder:"(555) 555-5555"},
  {id: "email", name: "email", label: "Email *", placeholder:"jane.deer@example.com"},
  {id:"preferCommunication", name:"preferCommunication", label: "Preferred method of communication", 
    items:[{id:"preferEmail", value:"Email"}, {id: "preferPhone", value: "Phone"}]},
];
const carInfo = [
  {id: "carYear", name: "carYear", label: "Year *", placeholder:"Select a year"},
  {id: "carMake", name: "carMake", label: "Make *", placeholder:"Enter your vehicle make"},
  {id: "carModel", name: "carModel", label: "Model *", placeholder:"Enter your vehicle model"},
  //{id: "carMileage", name: "carMileage", label: "Mileage *", placeholder:"Enter your car mileage on the odometer"},
  //{id: "carDoors", name: "carDoors", label: "Doors *", placeholder:"Select number of doors"},
  {id: "carDamage", name: "carDamage", label: "Damage Area *", placeholder:"Windshield, door glass, back glass"},
  {
    id: "carDamageSection", name: "carDamageSection", label: "Damage Section(s) *", placeholder:"Top, Middle, Bottom",
    items: [
      {id: "damageSectionDriverTop", value: "Driver-Top", label: "Driver-Top"},
      {id: "damageSectionCenterTop", value: "Center-Top", label: "Center-Top"},
      {id: "damageSectionPassengerTop", value: "Passenger-Top", label: "Passenger-Top"},
      {id: "damageSectionDriverMiddle", value: "Driver-Middle", label: "Driver-Middle"},
      {id: "damageSectionCenterMiddle", value: "Center-Middle", label: "Center-Middle"},
      {id: "damageSectionPassengerMiddle", value: "Passenger-Middle", label: "Passenger-Middle"},
      {id: "damageSectionDriverBottom", value: "Driver-Bottom", label: "Driver-Bottom"},
      {id: "damageSectionCenterBottom", value: "Center-Bottom", label: "Center-Bottom"},
      {id: "damageSectionPassengerBottom", value: "Passenger-Bottom", label: "Passenger-Bottom"},
    ],
  },
  {id: "causeOfDamage", name: "causeOfDamage", label: "Cause of Damage *", placeholder:"Select cause of the damage"}, 
  {id: "lossDate", name: "lossDate", label: "Date of Loss *", placeholder:"Select a date of loss"}, 
];
const bookingInfo = [
  {id: "location", name: "location", label: "Location *", placeholder:"",
    items:[{id: "locationPoco", value: PORT_COQUITLAM}, {id: "locationNorthVan", value: NORTH_VANCOUVER}]
  },
  {id: "bookingDate", name: "bookingDate", label: "Preferred Date *", placeholder:""},
  {id: "bookingTime", name: "bookingTime", label: "Preferred Time *", placeholder:""},
  // {id: "mobileService", name: "mobileService", label: "Mobile Service", 
  //   items:[{id: "mobileYes", value: "Yes"}, {id: "mobileNo", value: "No"}]},
  {id: "courtesyCar", name: "courtesyCar", label: "Courtesy Car", 
    items:[{id: "courtesyCarYes", value: "Yes"}, {id: "courtesyCarNo", value: "No"}]},
  {id: "needInsuranceClaim", name: "needInsuranceClaim", label: "Insurance Claim *", 
    items: [{id: "insuranceYes", value: "Yes"}, {id: "insuranceNo", value: "No"}]},
  {id: "insuranceCompany", name: "insuranceCompany", label: "Insurance Company"},
  {id: "comment", name: "comment", label: "Comments", placeholder: "Enter your comments"}
];

const hours = [
  "09:00",
  "11:00",
  "13:00",
  "15:00"
];

const isPastDate = date => {
  return date < new Date();
}

const SectionHeader = ({icon, title}) => (
  <div className="bg-blue-light p-2 text-white">
    <h4 className="flex items-center"><Icon name={icon} color="white" width="36px" /> <div className="ml-3">{title}</div></h4>
  </div>
);

const Divider = () => <hr className="my-5" />;

const FieldContainer = ({children}) => (
  <div className="mb-3">{children}</div>
);

const Field = ({field, formik, render, ...otherProps}) => (
  <FieldContainer>
    {field.label}
    <FormikField id={field.id} name={field.name} placeholder={field.placeholder} className="w-full" formik={formik} render={render} {...otherProps} />
  </FieldContainer>
);

const FieldInput = (props) => <Field {...props} render={Input} />
const FieldTextarea = (props) => <Field {...props} render={Textarea} />
const FieldDropdown = (props) => <Field {...props} render={Dropdown} />
const FieldRadioButton = ({field, formik, ...otherProps}) => (
  <FieldContainer>
    {field.label}
    <div className="flex">
    {
      map(d => 
        <FormikField 
          key={d.id} 
          id={d.id} 
          name={field.name} 
          value={d.value} 
          label={d.label} 
          formik={formik} 
          defaultChecked={d.value === formik.values[field.name]}
          render={RadioButton} 
          {...otherProps} 
          className="mr-24" 
        />, 
        field.items)
    }
    </div>
  </FieldContainer>
);
const FieldCheckboxList = ({field, formik, ...otherProps}) => (
  <FieldContainer>
    {field.label}
    <div className="grid grid-cols-3">
    {
      map(d => 
        <FormikField 
          key={d.id} 
          id={d.id} 
          name={field.name} 
          value={d.value} 
          label={d.label} 
          formik={formik} 
          defaultChecked={d.value === formik.values[field.name]}
          render={Checkbox} 
          {...otherProps} 
        />, 
        field.items)
    }
    </div>
  </FieldContainer>
);

const FieldInputList = ({fields, formik}) => fields.map(d => <FieldInput key={d.id} field={d} formik={formik} />);

const findField = (id, fields) => find(propEq('id', id), fields);

export default function BookingForm({id, location}) {
  const initValues = location === NORTH_VANCOUVER ? {...initialValues, location} : initialValues;
  const formik = useFormik({
    initialValues: initValues,
    validationSchema,
    onSubmit: handleSubmit
  });
  const [takenHours, setTakenHours] = React.useState([]);
  const [bookingDic, setBookingDic] = React.useState({});
  const [damageFiles, setDamageFiles] = React.useState([]);
  const [insuranceFiles, setInsuranceFiles] = React.useState([]);
  const [submitMessage, setSubmitMessage] = React.useState(null);
  const [hasError, setHasError] = React.useState(false);
  const [myBooking, setMyBooking] = React.useState(null);
  const [holidays, setHoliydays] = React.useState([]);
  const [currentYear, setCurrentYear] = React.useState((new Date()).getFullYear());
  const {showLoading} = useLoadingOveray();

  const needClaim = () => formik.values.needInsuranceClaim === "Yes";
  const isIcbcClaim = () => needClaim() && formik.values.insuranceCompany === "ICBC";
  const hasIcbcFile = () => insuranceFiles && insuranceFiles.length > 0;

  React.useEffect(() => {
    if(id){
      apiAppointment.getById(id).then(res => {
        const propNames = [
          "id",
          "firstName",
          "lastName",
          "phone",
          "email",
          "preferCommunication",
          "carYear",
          "carMake",
          "carModel",
          //"carDoors",
          //"carMileage",
          "carDamage",
          "causeOfDamage",
          "lossDate",
          "location",
          "bookingDate",
          "bookingTime",
          "needInsuranceClaim",
          "insuranceCompany",
          //"mobileService",
          "courtesyCar",
          "comment"
        ];
        if(res.data && typeof(res.data) === 'object'){
          if(formatDate(res.data.bookingDate) >= formatDate(new Date())){
            setMyBooking(res.data);
            propNames.forEach(p => formik.setFieldValue(p, prop(p, res.data)));
          }else{
            window.alert('Sorry, you cannot change past date\'s booking');
          }
        }

        init(res.data.bookingDate);
      }).catch(err => window.alert(err));
    } else {
      init();
    }

  }, [id]);

  React.useEffect(() => {
    if(currentYear >= (new Date()).getFullYear()){
      apiHoliday.getByProvince(currentYear).then(setHoliydays);
    }
  }, [currentYear]);

  const init = (date) => {
    setHasError(false);
    setSubmitMessage(null);

    const selectedDate = date ? getDate(date) : new Date();
    apiAppointment.get(firstDateOfMonth(selectedDate)).then(res => {
        setBookingDic(createBookingDic(res.data));
    });

    setCurrentYear(selectedDate.getFullYear());
  };

  const withLoading = (fnAync) => {
    showLoading(true);
    return fnAync().then(() => {
      showLoading(false);
    }).catch(err => {
      setHasError(true);
      showLoading(false);
    });
  };

  function handleSubmit(values){
    if(values.needInsuranceClaim === "Yes" && values.insuranceCompany === "ICBC" && insuranceFiles.length === 0){
      window.alert(ERROR_ICBC_FILE_MISSING);
      return;
    }
    withLoading(() => {
      const uploadFiles = [].concat(damageFiles).concat(insuranceFiles);
      if(uploadFiles.length > 0){
        const filePrefix = getFilePrefix();
        return apiUpload.upload(uploadFiles, filePrefix).then(res => {
          const files = map(prop('name'), uploadFiles);
          return addAppointment({...values, filePrefix, files});
        });
      } else {
        return addAppointment(values);
      }
    });
  }
  
  function addAppointment(values){
    const isUpdate = Boolean(myBooking);
    const updatedValues = isUpdate ? {...values, id: myBooking.id} : null;
    if(isUpdate && !values.files.length){
      updatedValues.filePrefix = myBooking.filePrefix;
      updatedValues.files = myBooking.files;
    }

    const addOrUpdate = isUpdate 
      ? apiAppointment.update(updatedValues) 
      : apiAppointment.add(values);

    return addOrUpdate.then(res => {
        if(typeof(res.data) === 'string' && res.data !== 'success'){
          console.log(res.data);
          window.alert(res.data);
        } else {
          console.log('res.data: ', res.data);
          setSubmitMessage(isUpdate ? "Your booking is updated." : "Your booking is successfully submitted.");
          setHasError(false);
        }
      });
  }

  function createBookingDic(dates){
    return reduce((acc, cur) => {
      const key = formatDate(cur.bookingDate);
      acc[key] = acc[key] ? acc[key].concat(cur.bookingTime) : [cur.bookingTime];
      return acc;
    }, {}, dates);
  } 

  const isDisabledDate = date => {
    if(isToday(date)){
      return true;
    }

    if(date.getDay() === 0){
      return true;
    }

    if(holidays.indexOf(formatDate(date)) >= 0){
      return true;
    }

    const bookedTimes = bookingDic[formatDate(date)] || [];
    if(bookedTimes && bookedTimes.length === hours.length){
      return true;
    }

    return false;
  };

  const handleChangeLossDate = date => {
    formik.setFieldValue("lossDate", date);
  };

  const handleChangeDate = (date) => {
    const dateStr = formatDate(date);
    const todayStr = formatDate(new Date());
    const curTime = formatTime(new Date());
    const bookingHours = bookingDic[dateStr] || [];
    if(dateStr === todayStr){
      hours.forEach(h => {
        if(h <= curTime){
          bookingHours.push(h);
        }
      })
    }
    setTakenHours(bookingHours);
    formik.setFieldValue("bookingDate", date);
    setCurrentYear(date.getFullYear());
  };

  const handleChangeTime = time => {
    formik.setFieldValue("bookingTime", time);
  };

  const handleChangeUpload = files => setDamageFiles(files);

  const handleChangeUploadInsurance = files => setInsuranceFiles(files);

  const handleCancel = () => {
    if(window.confirm('Are you sre to cancel this booking?')){
      if(myBooking){
        withLoading(() => apiAppointment.cancel(myBooking).then(() => {
          setSubmitMessage("Your booking has been canceled");
          setHasError(false);
        }));
      }
    }
  };

  const getFilePrefix = () => `${formatDate(formik.values.bookingDate)}_${formik.values.firstName}-${formik.values.lastName}-${formik.values.carModel}_`;

  return (
      <form onSubmit={formik.handleSubmit}>
        <Grid>
          <div>
            <SectionHeader icon="person" title="CONTACT INFORMATION" />
          </div>
          <div>
            <FieldInputList fields={take(4, userInfo)} formik={formik} />
            <FieldRadioButton field={findField("preferCommunication", userInfo)} formik={formik} />
          </div>
        </Grid>
        <Divider />
        <Grid>
          <div>
            <SectionHeader icon="car" title="YOUR VEHICLE" />
          </div>
          <div>
            <FieldDropdown field={findField("carYear", carInfo)} formik={formik} items={carYears} />
            <FieldInput field={findField("carMake", carInfo)} formik={formik} />
            <FieldInput field={findField("carModel", carInfo)} formik={formik} />
            {/* <FieldInput field={findField("carMileage", carInfo)} formik={formik} /> */}
            {/* <FieldDropdown field={findField("carDoors", carInfo)} formik={formik} items={carDoors} /> */}
            <FieldDropdown field={findField("carDamage", carInfo)} formik={formik} items={carDamages} />
            <FieldCheckboxList field={findField("carDamageSection", carInfo)} formik={formik} />
            <FieldDropdown field={findField("causeOfDamage", carInfo)} formik={formik} items={damageCuases} />
            <Field field={findField("lossDate", carInfo)} formik={formik} render={() => (
              <DatePicker 
                selected={ formik.values.lossDate ? getDate(formik.values.lossDate) : null} 
                onChange={handleChangeLossDate} 
                filterDate={isPastDate}
              />
            )} />
            <UploadFile id="damage-file" files={damageFiles} onChange={handleChangeUpload} />
          </div>
        </Grid>
        <Divider />
        <Grid>
          <div>
            <SectionHeader icon="magnifier" title="BOOKING INFORMATION" />
          </div>
          <div>
            <Field field={findField("bookingDate", bookingInfo)} formik={formik} render={() => (
              <div className="flex justify-center">
                <Calendar 
                  value={getDate(formik.values.bookingDate)} 
                  onChange={handleChangeDate} 
                  isDisabled={isDisabledDate}
                  disablePast={true} 
                  startOfWeek={0}
                />
              </div>
            )} />
            <Field field={findField("bookingTime", bookingInfo)} formik={formik} render={() => (
              <div>
                <TimeSelect value={formik.values.bookingTime} hours={hours} takenHours={takenHours} onChange={handleChangeTime} />
              </div>
            )} />
            <FieldRadioButton field={findField("location", bookingInfo)} formik={formik} />
            <FieldRadioButton field={findField("needInsuranceClaim", bookingInfo)} formik={formik} />
            {
              needClaim() && 
              <FieldDropdown field={findField("insuranceCompany", bookingInfo)} formik={formik} items={insuranceCompanys} />
            }
            {
              isIcbcClaim() &&
              <div className="mb-3">
                <UploadFile id="insurnace-file" files={insuranceFiles} onChange={handleChangeUploadInsurance} />
                {!hasIcbcFile() && <div className="text-red">{ERROR_ICBC_FILE_MISSING}</div>}
              </div>
            }
            <FieldTextarea field={findField("comment", bookingInfo)} formik={formik} />
            {/* <FieldRadioButton field={findField("mobileService", bookingInfo)} formik={formik} /> */}
            <FieldRadioButton field={findField("courtesyCar", bookingInfo)} formik={formik} />
          </div>
        </Grid>
        <Grid>
          <div></div>
          <div className="mt-4 text-blue-gray">Fields marked with an asterisk (*) are required</div>
        </Grid>
        <div className="text-right mt-4">
          { 
            submitMessage 
            ? <h2 className="text-blue">{submitMessage}</h2> 
            : <ButtonPrimary type="submit">{formik.values.id ? "UPDATE" : "SUBMIT"}</ButtonPrimary>
          }
          {!submitMessage && formik.values.id && <ButtonDanger type="button" className="ml-3" onClick={handleCancel}>CANCEL</ButtonDanger>}
          {hasError && <h4 className="text-red">Sorry, we have technical difficulties now. Please try it again later.</h4>}
        </div>
      </form>
  )
}
