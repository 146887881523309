import React from 'react';
import {sort, head, last, map, identity} from 'ramda';
import classNames from 'classnames';

import {formatDate} from '../../helper/datetime-helper';

export default function BookingList({data, selected, onSelect}) {
  const [minDate, maxDate] = React.useMemo(() => {
    const dates = sort(identity, map(d => formatDate(d.bookingDate), data));
    return [head(dates), last(dates)];
  }, [data]);

  const isSelected = d => d === selected;

  return (
    <ul>
      {
        data.map(d => 
          <li key={d.id} onClick={() => onSelect(d)} className={classNames("cursor-pointer p-2 rounded-md", {'bg-orange': isSelected(d), 'text-white': isSelected(d)})}>
            [{d.location}] {minDate !== maxDate ? formatDate(d.bookingDate) : null} {d.bookingTime} - {d.firstName} {d.lastName}, {d.carMake} {d.carModel} {d.carYear}
          </li>)
      }
      {
        data.length === 0 && <div>No bookings found</div>
      }
    </ul>
  );
}
