import React from 'react';
import {endsWith, any, __, propEq} from 'ramda';

import {formatDate} from '../../helper/datetime-helper';
import ModalBox from '../ModalBox';

const style = {
  label: {width: '35%', minWidth: '120px'}
};

const isImageFile = file => {
  const imageExts = ['.jpg', '.jpeg', '.png', '.gif'];
  return any(endsWith(__, file), imageExts);
};

const getFileUrl = (prefix, name) => `https://cs44d30b6bf5cacx4c19xb27.blob.core.windows.net/bcautoglass/${prefix}${name}`;

const joinString = arr => arr && arr.length ? arr.join(", ") : "";

export default function BookingDetail({data}) {
  const [imageUrl, setImageUrl] = React.useState(null);

  const RowData = ({field, label, render}) => (
    <div className="flex">
      <div style={style.label}>{label}:</div>
      <div>{render ? render(data[field]) : data[field]}</div>
    </div>
  );

  const handleImageClick = src => {
    setImageUrl(src);
  };

  const renderFile = files => {
    return (
      <ul>
        {files.map(d => 
          <li key={d} className="mb-2">
            {
              isImageFile(d) 
              ? <img src={getFileUrl(data.filePrefix, d)} alt={d} className="cursor-pointer" onClick={() => handleImageClick(getFileUrl(data.filePrefix, d))} />
              : <a href={getFileUrl(data.filePrefix, d)} target="_blank" rel="noreferrer">{d}</a>
            }
          </li>
        )}
      </ul>
    );
  };

  return (
    <div className="p-2">
      <h3>{data.location}</h3>
      <h4>Contact</h4>
      <RowData field="firstName" label="First Name" />
      <RowData field="lastName" label="Last Name" />
      <RowData field="phone" label="Phone" />
      <RowData field="email" label="Email" />
      <RowData field="preferCommunication" label="Contact Preference" />
      
      <h4 className="mt-3">Vehicle</h4>
      <RowData field="carMake" label="Make" />
      <RowData field="carModel" label="Model" />
      <RowData field="carYear" label="Year" />
      <RowData field="carDoors" label="Doors" />
      <RowData field="carMileage" label="Mileage" />
      <RowData field="carDamage" label="Damage" />
      <RowData field="carDamageSection" label="Damage Section" render={joinString} />
      <RowData field="causeOfDamage" label="Cause of Damage" />
      <RowData field="lossDate" label="Date of Loss" render={formatDate} />
      
      <h4 className="mt-3">Booking Information</h4>
      <RowData field="bookingDate" label="Date" render={formatDate} />
      <RowData field="bookingTime" label="Time" />
      <RowData field="location" label="Location" />
      <RowData field="needInsuranceClaim" label="Insurance" />
      {
        propEq("needInsuranceClaim", "Yes", data) &&
        <RowData field="insuranceCompany" label="Insuranace Company" />
      }
      <RowData field="mobileService" label="Mobile Service" />
      <RowData field="comment" label="Comment" />
      <RowData field="files" label="Files" render={renderFile} />
      <ModalBox show={Boolean(imageUrl)} onClose={() => setImageUrl(null)}>
        <img src={imageUrl} alt="" />
      </ModalBox>
    </div>
  );
}
