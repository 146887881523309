import React from 'react';
import {useInView} from 'react-intersection-observer';
import {motion} from 'framer-motion';

import {getFadeInMotion, getTopDownMotion} from '../helper/motion';
import {getImagePath} from '../helper/image-helper';
import BgImage from './BgImage';
import Image from './Image';
import Parallax from './Parallax';
import Center from './Center';
import SplitLetter from './SplitLetter';

const style ={
  partnerLogo: {maxWidth: '180px', marginBottom: '20px'},
};
const partnerLogoStyle = {
  "meadowridge": {maxWidth: '300px', marginBottom: '20px'}
};

const getImage = getImagePath('main');

const partners = [
  {name: 'uhaul', logo: getImage('uhaul_logo.png')},
  {name: 'cypress', logo: getImage('cypress_logo.png')},
  {name: 'mountainhwy', logo: getImage('mountainhwy_logo.jpg')},
  {name: 'mev', logo: getImage('mev_logo.png')},
  {name: 'alphaauto', logo: getImage('alpha_auto_logo.jpg')},
  {name: 'pacificrental', logo: getImage('pacific_rental_logo.png')},
  {name: 'locost', logo: getImage('lo-cost_rental_logo.jpg')},
  //{name: 'napa', logo: getImage('napa_autopro_logo.jpg')},
  //{name: 'a1auto', logo: getImage('a1_auto_logo.png')},
  {name: 'meadowridge', logo: getImage('meadow-ridge-coillision.png')},
];

const topDown = getTopDownMotion();
const fadeInOurwork = getFadeInMotion({animate: {transition: {delay: 2}}});
const fadeInPartner = getFadeInMotion({animate: {transition: {staggerChildren: 0.8, delayChildren: 0.5}}});
const fadeInLogo = getTopDownMotion();

const contents = [
  "BC Auto Glass is a Metro Vancouver based leader in the auto glass repair and replacement industry. ",
  "Over the past 20 years, we have been serving customers with attention to detail and ethics that stem from being a family owned and operated business. ",
  "We listen to our customers and work hard to meet their needs by providing the very best quality products at a fair and competitive price. ",
  "We are proud to be the only company in British Columbia to offer real time instant quotes online 24/7. ",
  "This enables our clients to obtain an auto glass quote instantly in less than 30 seconds. ",
  "BC Auto Glass provides the mobile service in Metro Vancouver anywhere with no hidden fees and also lifetime warranty. ",
  "BC Auto Glass deals with ICBC and all major insurance companies. ",
];

export default function OurWork() {
  const [ref, inView] = useInView({rootMargin: '-200px 0px', triggerOnce: true});

  return (
    <motion.div ref={ref} variants={topDown} initial="initial" animate={inView ? "animate" : "initial"} className="grid grid-cols-1 lg:grid-cols-2">
      <BgImage src={getImage('ourwork_bg.jpg')} className="p-10">
        <Center>
          <motion.div variants={fadeInPartner} className="grid grid-cols-1 sm:grid-cols-3 justify-center items-center sm:w-full">
          {
            partners.map(d => (
              <motion.div 
                variants={fadeInLogo} 
                key={d.name}
              >
                <Parallax>
                  <Center>
                    <Image src={d.logo} alt={d.name} style={partnerLogoStyle[d.name] ? partnerLogoStyle[d.name] : style.partnerLogo}/>
                  </Center>
                </Parallax>
              </motion.div>
            ))
          }
          </motion.div>
        </Center>
      </BgImage>
      <div className="bg-gray py-10">
        <div className="w-1/2 mx-auto">
          <SplitLetter delay={1}>Our Work</SplitLetter>
          <motion.div>
            {contents.map((d, i) => <motion.div key={i} variants={fadeInOurwork} className="font-bold">{d}</motion.div>)}
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
}
