import React from 'react';
import {Link} from 'react-router-dom';

import Logo from './Logo';
import TopNav from './TopNav';
import TopNavMobile from './TopNavMobile';
import Container from './Container';
import IcbcLogo from './IcbcLogo';

export default function Header({navItems}) {
  return (
    <div className="absolute top-0 left-0 z-10 w-full flex justify-center">
        <div className="flex items-end items-center">
            <Link to="/"><Logo /></Link>
          <div className="ml-3 mb-3 lg:hidden">
            <TopNavMobile items={navItems} />
            <IcbcLogo style={{height:'50px'}} />
          </div>
          <div className="hidden lg:block pl-3">
            <TopNav items={navItems} />
          </div>
        </div>
    </div>
  )
}
