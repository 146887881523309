import React from 'react';
import {motion} from 'framer-motion';
import {useInView} from 'react-intersection-observer';
import classNames from 'classnames';

const itemMotion = {
  initial: {opacity: 1},
  animate: {
    opacity: 1,
    transition: {
      staggerChildren: 0.5
    }
  },
};

const defaultGridStyle = "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mb-16";

export default function GridItems({items, render, className, cols="4"}) {
  const [ref, inView] = useInView({triggerOnce: true, rootMargin: '-200px 0px'});

  return (
    <motion.div 
      ref={ref} 
      variants={itemMotion} 
      initial="initial" 
      animate={inView ? "animate" : "initial"} 
      className={classNames(`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-${cols} gap-4 mb-16`, className)}
    >
      {items.map((d, i) => render(d))}
    </motion.div>
  );
}
