import React from 'react';
import {Link} from 'react-router-dom';

import {PATH_APPOINTMENT, PORT_COQUITLAM, NORTH_VANCOUVER} from '../helper/constant';
import {getImagePath} from '../helper/image-helper';
import ButtonDefault from './ButtonDefault';
import SplitLetter from './SplitLetter';
import MotionTopDown from './MotionTopDown';
import TopHero from './TopHero';

const style ={
  heroContent: {left: '50%', top: '50%', transform: 'translate(-50%, -50%)'},
};

const getImage = getImagePath('main');
const getBookingUrl = location => `${PATH_APPOINTMENT}?location=${location}`;

export default function HomeHero() {
  return (
    <TopHero image={getImage('top_max.jpg')} height="100vh">
      <div className="absolute text-center" style={style.heroContent}>
        <SplitLetter className="text-white whitespace-nowrap">
          Broken glass?
        </SplitLetter>
        <SplitLetter className="text-green mt-2" wrapper="h1" delay={2.3} stagger={0.06}>
          Book an appointment!
        </SplitLetter>
        <MotionTopDown delay={4} duration={1}>
          <div className="mt-8 whitespace-nowrap grid grid-cols-1 md:grid-cols-2 gap-3">
            <BookingButton location={PORT_COQUITLAM} />
            <BookingButton location={NORTH_VANCOUVER} />
          </div>
        </MotionTopDown>
      </div>
    </TopHero>
  )
}

function BookingButton({location}){
  return (
    <Link to={getBookingUrl(location)}>
      <ButtonDefault style={{width: '200px'}}>
        <h5>Book Now</h5>
        <div>{location}</div>
      </ButtonDefault>
    </Link>
  );
}
