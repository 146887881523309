import React from 'react';

import useUrlParams from './useUrlParams';
import Container from './Container';
import BookingForm from './BookingForm';
import PageLayout from './PageLayout';

const Desc = () => (
  <div className="mb-5 text-xl font-bold">
    Fill out the form below* and book your appointment to repair or replace your automotive glass.
  </div>
);
const AppointmentForm = ({id, location}) => (
  <Container className="p-4">
    <BookingForm id={id} location={location} />
  </Container>
);

export default function AppointmentPage() {
  const param = useUrlParams();
  const id = param.get('id');
  const location = param.get('location');

  return (
    <PageLayout 
      imagePath="contact"
      pageTitle="Online Appointment"
      contentTitle=""
      renderChild1={Desc}
      renderChild2={() => <AppointmentForm id={id} location={location} />}
    />
  );
}
