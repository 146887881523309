
import React from 'react';
import RouterRoot from './component/RouterRoot';
import {BrowserRouter as Router} from 'react-router-dom';

import authApi from './api/auth-api';
import { ModernizrProvider } from './component/ModernizrContext';
import ScrollToTop from './component/ScrollToTop';
import {LoadingOverayProvider} from './component/LoadingOverlayContext';
import {AuthProvider} from './component/AuthContext';

authApi.init();

function App() {
  return (
    <ModernizrProvider>
      <AuthProvider>
        <Router>
          <ScrollToTop>
            <LoadingOverayProvider>
              <RouterRoot />
            </LoadingOverayProvider>
          </ScrollToTop>
        </Router>
      </AuthProvider>
    </ModernizrProvider>
  );
}

export default App;
